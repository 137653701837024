
export default {
  name: 'Listitens',
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        direction: 'horizontal',
        lazy: false,
        slidesPerView: 2.5,
        slidesPerGroup: 1,
        breakpointsInverse: true,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1'
        },
        breakpoints: {
          992: {
            slidesPerView: 8,
            slidesPerGroup: 1,
            spaceBetween: 0
          },
          350: {
            slidesPerView: 3.5,
            slidesPerGroup: 1,
            spaceBetween: 10
          },
          550: {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 0
          }
        }
      }
    }
  },
  props: {
    content: {
      type: Array,
      default() {}
    },
    txtColors: {
      type: String,
      default: '#000000'
    }
  },
  computed: {
    cssProps() {
      return {
        '--txt-color': this.txtColors
      }
    }
  }
}
